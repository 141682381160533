import { ReactComponent as Begegnungen } from "./Begegnungen.svg"
import { ReactComponent as Buecher } from "./Buecher.svg"
import { ReactComponent as Dinge } from "./Dinge.svg"
import { ReactComponent as Rabatt } from "./Rabatt.svg"
import { ReactComponent as Raum } from "./Raum.svg"
import { ReactComponent as Service } from "./Service.svg"
import useWindowResize from "../../hooks/useWindowResize"
import { useNavigate } from "react-router-dom"

const BubbleSizes = {
  mini: "h-32 w-32",
  small: "h-36 w-36",
  medium: "h-40 w-40",
  large: "h-48 w-48",
  huge: "h-56 w-56",
}

type Props = {
  size: string
  bgClassName: string
  svgClass?: string
  bubbleClass: string
  Svg: any
  onClick?: () => void
}

const Bubble: React.FC<Props> = ({ size, bgClassName, svgClass, bubbleClass, Svg, onClick }) => {
  return (
    <div onClick={onClick} className={`mr-2 group cursor-pointer  ${size ?? ""} ${bubbleClass ?? ""} relative aspect-square`}>
      {
        // Background bubble
        <div className={`
        absolute rounded-full 
        group-hover:scale-105 group-hover:mix-blend-normal group-hover:opacity-100
        transition duration-400 
        ${bgClassName ?? ""} 
        w-full h-auto 
        aspect-square
        `} />
      }
      <Svg className={` ${svgClass ?? ""} absolute w-full h-auto`} />
    </div>
  )
}

const Bubbles = () => {

  const { isMobile } = useWindowResize()

  const navigate = useNavigate()

  const handleNavigate = (path: string) => {
    navigate(path)
    window.scrollTo(0, 0)
  }

  return (
    <div>
      {
        /* First Line */
        <div className={`flex flex-wrap justify-center items-end px-4 py-4`}>
          {
            isMobile ?
              (
                // Mobile
                <>
                  <Bubble
                    size="w-[38%]"
                    bgClassName="mix-blend-multiply bg-petrol opacity-70"
                    Svg={Buecher}
                    bubbleClass="absolute z-20"
                    onClick={() => handleNavigate("/buecher")}
                  />

                  <Bubble
                    size="w-[38%]"
                    bgClassName="bg-[#25ac1e] group-hover:bg-gruen opacity-70"
                    Svg={Dinge}
                    bubbleClass="absolute z-20"
                    onClick={() => handleNavigate("/dinge")}
                  />

                  <div className="basis-full flex justify-center items-center">
                    {
                      // Service
                      <Bubble
                        size="w-[35%]"
                        bgClassName="bg-iron mix-blend-multiply opacity-80"
                        Svg={Service}
                        bubbleClass="absolute z-20"
                        onClick={() => handleNavigate("/buecher")}
                        />
                    }



                    <Bubble
                      size="w-[55%]"
                      bgClassName="bg-[#ff7800] hover:bg-gruen opacity-70"
                      Svg={Begegnungen}
                      bubbleClass="absolute z-20 my-2"
                      onClick={() => handleNavigate("/begegnungen")}
                    />
                   


                  </div>



                  <div className="basis-full flex justify-center">
                    {
                      // Rabatt
                                          <Bubble
                      size="w-[40%]"
                      bgClassName="bg-hellbeige opacity-70"
                      Svg={Rabatt}
                      bubbleClass="absolute z-20 mr-4"
                      onClick={() => handleNavigate("/buecher")}
                    />
                    }

                    <Bubble
                      size="w-[40%]"
                      bgClassName="bg-violet opacity-80"
                      Svg={Raum}
                      bubbleClass="absolute z-20"
                      onClick={() => handleNavigate("/raum")}
                    />

                  </div>
                </>
              )
              :
              <>
                <Bubble
                  size={BubbleSizes.small}
                  bgClassName="mix-blend-multiply bg-petrol opacity-70"
                  Svg={Buecher}
                  bubbleClass="absolute z-20"
                  onClick={() => handleNavigate("/buecher")}
                />
                <Bubble
                  size={BubbleSizes.small}
                  bgClassName="bg-[#25ac1e] group-hover:bg-gruen opacity-70"
                  Svg={Dinge}
                  bubbleClass="absolute z-20"
                  onClick={() => handleNavigate("/dinge")}
                />
                <Bubble
                  size={BubbleSizes.medium}
                  bgClassName="bg-[#ff7800] hover:bg-gruen opacity-70"
                  Svg={Begegnungen}
                  bubbleClass="absolute z-20 mr-16"
                  onClick={() => handleNavigate("/begegnungen")}
                />

                <div className="basis-full mt-1"></div>
                {
                  // Service
                  <Bubble
                  size={BubbleSizes.small}
                  bgClassName="bg-iron mix-blend-multiply opacity-80"
                  Svg={Service}
                  bubbleClass="absolute z-20 ml-16"
                  onClick={() => handleNavigate("/buecher")}
                />
                }

                {
                  // Rabatt
                               <Bubble
                  size={BubbleSizes.small}
                  bgClassName="bg-hellbeige opacity-70"
                  Svg={Rabatt}
                  bubbleClass="absolute z-20"
                  onClick={() => handleNavigate("/buecher")}
                /> }

                <Bubble
                  size={BubbleSizes.small}
                  bgClassName="bg-violet opacity-80"
                  Svg={Raum}
                  bubbleClass="absolute z-20"
                  onClick={() => handleNavigate("/raum")}
                />
              </>
          }

        </div>
      }
    </div>
  )
}

export default Bubbles