import { RefObject, useEffect, useState } from "react"
import useWindowResize from "../hooks/useWindowResize"

interface Props {
  headlineRef: RefObject<HTMLDivElement>
}

const HeadlineMagic : React.FC<Props> = ({ headlineRef }) => {
  const [height, setHeight] = useState(157)
  const { isMobile } = useWindowResize()

  useEffect(() => {
    if(headlineRef && headlineRef.current){
      setHeight(headlineRef.current.clientHeight)
    }
  }, [headlineRef, isMobile])

  return (
    <div style={{ height: `${height}px`}} />
  )
}

export default HeadlineMagic